<template>
    <v-row>
        <v-col cols="12" lg="8" md="12">
            <v-row>
                <v-col cols="12" md="4">
                    <!-- test -->
                    <crypto-currency-card
                        bg-card-color="primary"
                        heading="Daily Income"
                        sub-heading="2.00 USD"
                        main-icon-text-color="primary--text text--lighten-2"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <crypto-currency-card
                        bg-card-color="primary"
                        heading="Monthly Income"
                        sub-heading="73.90 USD"
                        main-icon-text-color="primary--text text--lighten-2"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <crypto-currency-card
                        bg-card-color="primary"
                        heading="Yearly Income"
                        sub-heading="1440.30 USD"
                        main-icon-text-color="primary--text text--lighten-2"
                    />
                </v-col>
                <v-col cols="12">
                    <chart-card title="Value Over Time" button-text="last 30 days">
                        <template slot="chart">
                            <div id="basicArea-chart" style="min-height: 365px">
                                <apexchart
                                    type="area"
                                    height="350"
                                    :options="customChartEight.chartOptions"
                                    :series="customChartEight.series"
                                />
                            </div>
                        </template>
                    </chart-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" lg="8" md="12">
            <base-card>
                <v-card-text>
                    <v-tabs v-model="tab">
                        <v-tab v-for="item in items" :key="item.tab">
                            {{ item.tab }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="item in items" :key="item.tab">
                            <v-card flat>
                                <v-card-text>
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Project
                                                </th>
                                                <th class="text-left">
                                                    Currency
                                                </th>
                                                <th class="text-left">
                                                    Holdings
                                                </th>
                                                <th class="text-left">
                                                    Last Price
                                                </th>
                                                <th class="text-left">
                                                    Total
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(itemTwo,
                                                index) in item.content"
                                                :key="index"
                                            >
                                                <td>{{ itemTwo.project }}</td>
                                                <td>{{ itemTwo.currency }}</td>
                                                <td>{{ itemTwo.holdings }}</td>
                                                <td>{{ itemTwo.lastPrice }}</td>
                                                <td>{{ itemTwo.holdings * itemTwo.lastPrice }}</td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </base-card>
        </v-col>
    </v-row>
</template>
<script>
import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard'
import {priceChart, marketChart} from '@/data/cryptoCurrency'
import ChartCard from '@/components/card/ChartCard'

import {
    customChartOne,
    customChartTwo,
    customChartThree,
    customChartFour,
    customChartFive,
    customChartSix,
    customChartSeven,
    customChartEight,
    customChartNine,
    customChartTen,
    customChartEleven,
    customChartTwelve,
    customChartThirteen,
    customChartFourteen,
    customChartFifteen,
    customChartSixteen,
    customChartSeventeen,
    customChartEighteen
} from '@/data/apexChart'
export default {
    name: 'dashboard',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Dashboard'
    },
    components: {
        CryptoCurrencyCard,
        ChartCard
    },
    data() {
        return {
            customChartOne,
            customChartTwo,
            customChartThree,
            customChartFour,
            customChartFive,
            customChartSix,
            customChartSeven,
            customChartEight,
            customChartNine,
            customChartTen,
            customChartEleven,
            customChartTwelve,
            customChartThirteen,
            customChartFourteen,
            customChartFifteen,
            customChartSixteen,
            customChartSeventeen,
            customChartEighteen,
            priceChart,
            marketChart,
            tab: null,
            items: [
                {
                    tab: 'Current Value',
                    content: [
                        {
                            project: 'VaporNodes',
                            currency: 'VPND',
                            holdings: 209091.05,
                            lastPrice: 0.002273
                        },
                        {
                            project: 'YieldNodes',
                            currency: 'EURO',
                            holdings: 359.62,
                            lastPrice: 1
                        },
                        {
                            project: 'Drip',
                            currency: 'DRIP',
                            holdings: 28.764,
                            lastPrice: 0.992273
                        },
                        {
                            project: 'StableFund',
                            currency: 'SFUSD',
                            holdings: 279.6636,
                            lastPrice: 1
                        },
                        {
                            project: 'MayoTrade',
                            currency: 'USDT',
                            holdings: 101.02,
                            lastPrice: 1
                        },
                        {
                            project: 'Optimus',
                            currency: 'OPT3',
                            holdings: 349.98,
                            lastPrice: 0.247106
                        },
                        {
                            project: 'Prosperity Gem Ventures',
                            currency: 'USDT',
                            holdings: 250.06,
                            lastPrice: 1
                        }
                    ]
                }
            ],
            order: null,
        }
    },
    methods: {
        getColor(lastPrice) {
            if (lastPrice > 4000) return 'danger--text'
            else if (lastPrice > 1000) return 'success--text'
            else return 'priamry--text'
        },
        changeColor(number) {
            if (number > 4) return 'danger white--text'
            else if (number <= 3) return 'success white--text'
            else return 'primary white--text'
        }
    }
}
</script>
